import { CommunityEvent } from './community-event';

export interface Learner {
  id: string;
  legacyId: string;
  contact: {
    givenName: string;
    familyName: string;
    emailAddress: string;
  };
  event: CommunityEvent;
  lastUpdated: string;
  created: string;
  orderDate: string;
  status: LearnerStatus;
  cancellationRequiresApproval: boolean;
}

export enum LearnerStatus {
  COMPLETE = 'COMPLETE',
  ENROLLED = 'ENROLLED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}
