import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeveloperToolsPageComponent } from './page/developer-tools-page/developer-tools-page.component';
import { DevelopersPageComponent } from './page/developers-page/developers-page.component';
import { EventDetailPageComponent } from './page/event-detail-page/event-detail-page.component';
import { EventListPageComponent } from './page/event-list-page/event-list-page.component';
import { ThemeGuard } from './theme.guard';
import { Theme } from './theme.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'events'
  },
  {
    path: 'developer',
    redirectTo: 'developers'
  },
  {
    path: 'developers',
    canActivate: [ ThemeGuard ],
    canDeactivate: [ ThemeGuard ],
    data: {
      theme: Theme.dark
    },
    children: [
      {
        path: '',
        component: DevelopersPageComponent
      },
      {
        path: 'tools',
        component: DeveloperToolsPageComponent
      }
    ]
  },
  {
    path: 'events',
    component: EventListPageComponent
  },
  {
    path: 'event/:id',
    redirectTo: 'events/:id'
  },
  {
    path: 'events/:id',
    component: EventDetailPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [ 0, 67 ],
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
