<div class="mb-3">
  <button
    class="btn btn-dark"
    (click)="filterValuesChange.next({})">Clear filters
  </button>
</div>
<div class="d-flex flex-wrap mb-3">
  @for (filter of filterValuesChange.value | keyvalue; track filter) {
    @for (value of filter.value; track value) {
      <backstage-chip
        [removable]="true"
        (remove)="removeFilterValue(filter, value)">{{ value }}
      </backstage-chip>
    }
  }
</div>
<ng-content/>
