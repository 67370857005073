<div class="p-3 d-flex flex-column h-100">
  <div
    class="mb-3">
    <h5 class="mb-3 text-uppercase fw-bolder text-gray-500 fs-6">Upcoming Events</h5>
    @for (registration of futureLearners; track registration) {
      <app-registration-detail-card [learner]="registration"/>
    }
    @if (!futureLearners.length) {
      <p class="text-gray-500">You're not registered on any upcoming events</p>
    }
  </div>
  @if (pastLearners.length) {
    <div
      class="mb-3 border-top pt-3">
      <h5 class="mb-3 text-uppercase fw-bolder text-gray-500 fs-6">Past Events</h5>
      @for (learner of pastLearners; track learner) {
        <app-registration-detail-card [learner]="learner"/>
      }
    </div>
  }
  <div class="border-top mt-auto mb-5 pt-3">
    To cancel a registration, please contact us at
    <a href="mailto:community@forgerock.com">community&#64;forgerock.com</a>
  </div>
</div>
