import { Component, OnInit } from '@angular/core';
import { CommunityEventService } from '../community-event.service';
import { Learner } from '../model/learner';

@Component({
  selector: 'app-registration-list',
  templateUrl: './registration-list.component.html',
  styleUrls: [ './registration-list.component.scss' ]
})
export class RegistrationListComponent implements OnInit {
  futureLearners: Learner[] = [];
  pastLearners: Learner[] = [];

  constructor(private communityEventService: CommunityEventService) {}

  async ngOnInit(): Promise<void> {
    try {
      const now = new Date().toISOString();
      const learners = await this.communityEventService.getRegistrations();
      this.futureLearners = learners.filter(it => it.event.startInstant >= now);
      this.pastLearners = learners.filter(it => it.event.startInstant < now);
    } catch {
      // ignored
    }
  }

}
