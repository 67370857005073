<div class="mt-3 bg-gray-200 p-3 text-center rounded position-relative">
  <backstage-spinner
    [spinning]="spinning"
    position="absolute"/>
  @if (!spinning) {
    @if (loggedIn) {
      @if (registered) {
        <div class="text-green-700 mb-2">
          <i class="bi bi-check-circle-fill me-1"></i> You are registered on this event
        </div>
        <div ngbDropdown>
          <button
            class="btn btn-light"
            ngbDropdownToggle>Add to my calendar
          </button>
          <div ngbDropdownMenu>
            <a
              ngbDropdownItem
              [href]="event | calendarLink: 'ics'"
              download="{{event.id}}.ics">Download .ics file</a>
            <a
              ngbDropdownItem
              target="_blank"
              [href]="event | calendarLink: 'google'">Add to Google Calendar</a>
            <a
              ngbDropdownItem
              target="_blank"
              [href]="event | calendarLink: 'outlook'">Add to Outlook Live Calendar</a>
            <a
              ngbDropdownItem
              target="_blank"
              [href]="event | calendarLink: 'office365'">Add to Office 365 Calendar</a>
            <a
              ngbDropdownItem
              target="_blank"
              [href]="event | calendarLink: 'yahoo'">Add to Yahoo!</a>
          </div>
        </div>
      } @else {
        @if (emailVerified) {
          <button
            type="button"
            class="btn btn-primary btn-lg"
            (click)="register()">Register now
          </button>
        } @else {
          <p>
            <i class="bi bi-envelope-exclamation display-6 text-gray-600"></i>
          </p>
          <p class="fw-bold verification-expired">
            Your email verification has expired. Please renew your verification to register on this event.
          </p>
          <p class="mb-1">
            <a
              class="btn btn-light"
              target="_blank"
              href="/account/profile">Manage your account</a>
          </p>
        }
      }
    } @else {
      <button
        type="button"
        class="btn btn-primary btn-lg"
        (click)="redirectToLogin()">Log in to Register
      </button>
      <div class="mt-1">
        <a href="mailto:community@forgerock.com?subject=Need help registering for community event [{{event.title}}]">Ask
          for help</a>
      </div>
    }
  }
</div>
