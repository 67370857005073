<backstage-spinner [spinning]="spinning"/>
@if (event) {
  <div class="bg-gray-200">
    <div class="container px-md-0 py-4 mb-4">
      <h1 class="display-6 fw-bolder">{{ event.title }}</h1>
      <div class="fs-5 text-gray-500 text-uppercase d-flex align-items-center">
        @if (event.series) {
          <div
            class="badge border-{{event.series | colorize}} border text-{{event.series | colorize}} fw-normal me-2">
            {{ event.series | replace: '_' : ' ' }}
          </div>
        }
        Community Event
      </div>
      @if (event.guestSpeaker) {
        <div
          class="mt-3 pt-3 border-top fw-bold border-dark border-opacity-25">
          Guest speaker: {{ event.guestSpeaker }}
        </div>
      }
    </div>
  </div>
  <div class="container">
    <div class="row flex-row-reverse">
      <div class="col-md-6 col-xl-3 mb-3">
        <div class="card bg-white shadow-sm">
          <table class="table mb-0 w-auto">
            <tbody>
            <tr>
              <th class="text-uppercase text-gray-600 text-nowrap">
                <i class="bi bi-calendar2 me-1"></i> Start
              </th>
              <td>{{ event.startInstant | date: 'MMM dd, yyyy, h:mm a O' }}</td>
            </tr>
            <tr>
              <th class="text-uppercase text-gray-600 text-nowrap">
                <i class="bi bi-calendar2-check me-1"></i> End
              </th>
              <td>{{ event.endInstant | date: 'MMM dd, yyyy, h:mm a O' }}</td>
            </tr>
            <tr>
              <th class="text-uppercase text-gray-600 text-nowrap">
                <i class="bi bi-globe me-1"></i> Region
              </th>
              <td>{{ event.venueLocation }}</td>
            </tr>
              @if (event.language) {
                <tr>
                  <th class="text-uppercase text-gray-600 text-nowrap">
                    <i class="bi bi-flag-fill me-1"></i> Language
                  </th>
                  <td>{{ event.language }}</td>
                </tr>
              }
              @if (event.modality) {
                <tr>
                  <th class="text-uppercase text-gray-600 text-nowrap">
                    <i class="bi bi-person-video me-1"></i> Modality
                  </th>
                  <td>{{ event.modality }}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <app-social-media-buttons [event]="event"></app-social-media-buttons>
        <app-event-registration [event]="event"></app-event-registration>
      </div>
      <div class="col-md-6 col-xl-9">
        <div
          class="text-gray-800 pb-5"
          [innerHTML]="event.summary"></div>
      </div>
    </div>
  </div>
}
