<backstage-site-header
  active="community"
  fluid="fluid"
  class="border-bottom border-light border-opacity-25 d-block position-fixed"></backstage-site-header>
<div
  class="wrapper"
  [class.dark-theme]="themeService.darkTheme | async">
  <backstage-notifications [connect]="true"/>
  <div class="d-flex flex-fill">
    <app-nav></app-nav>
    <backstage-drawer-spacer position="left"/>
    <div class="pb-4 flex-fill">
      <router-outlet/>
    </div>
  </div>
</div>
<div class="position-relative">
  <div class="feedback-wrapper bg-steel-blue_to_cobalt p-3 position-absolute w-100 text-white text-center">
    <backstage-feedback buttonClass="text-lightblue-200 text-white-hover"/>
  </div>
</div>
<backstage-site-footer fluid="fluid"></backstage-site-footer>
