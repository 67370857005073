import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
  constructor(public themeService: ThemeService,
              @Inject(DOCUMENT) private document: Document) {
    const className = 'theme-dark';
    themeService.darkTheme.subscribe(darkTheme => {
      if (darkTheme) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    });
  }

}
