import { Injectable } from '@angular/core';
import { and, filter, BackendService, value } from '@forgerock/backstage-ui-core';
import { EverythingSearchService } from './everything-search.service';

@Injectable({
  providedIn: 'root'
})
export class SearchFactoryService {

  constructor(private backend: BackendService) { }

  forumThreads(): EverythingSearchService {
    return new EverythingSearchService(this.backend)
      .withSort('updateTimestamp', false)
      .withNestedFilter(
        and(
          value(filter('source', 'community')),
          value(filter('entityType', 'THREAD')),
        )
      )
  }

  marketplaceEntries(): EverythingSearchService {
    return new EverythingSearchService(this.backend)
      .withSort('updateTimestamp', false)
      .withNestedFilter(
        and(
          value(filter('source', 'marketplace-entry')),
          value(filter('entityType', 'SOFTWARE_ARTIFACT')),
        )
      )
  }
  knowledgeArticles(): EverythingSearchService {
    return new EverythingSearchService(this.backend)
      .withSort('updateTimestamp', false)
      .withNestedFilter(
        and(
          value(filter('source', 'knowledge')),
          value(filter('entityType', 'ARTICLE')),
        )
      )
  }
}
