<div class="mb-3 card shadow-sm border-0">
  <div class="card-body d-flex">
    <div class="pe-3">
      <div class="date bg-gray-200 rounded-3 d-flex flex-column">
        <div class="bg-primary rounded-top text-white text-center small">
          {{learner.event.startInstant | date: 'MMM'}}
        </div>
        <div class="d-flex align-items-center justify-content-center fs-3">
          {{learner.event.startInstant | date: 'dd'}}
        </div>
      </div>
    </div>
    <div>
      <a routerLink="/events/{{learner.event.id}}">{{learner.event.title}}</a>
    </div>
  </div>
</div>
