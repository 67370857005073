<div class="container bg-developers p-5 text-white">
  <div class="my-5">
    <h1 class="fw-bolder display-5 mb-3">
      Fundamentals for <span class="text-clip bg-cobalt_to_verdigris">developers</span><br>
      to build secure applications
    </h1>
    <p class="lead mb-0 text-white fw-normal fs-4">
      Curated <a
      href="https://community.forgerock.com/c/getting-started-guides/36"
      target="_blank">quick start guides</a>
      and development resources for the ForgeRock platform
    </p>
  </div>
  <div class="mb-5 pt-5">
    <backstage-layout-grid [gap]="3">
      <backstage-layout-grid-cell [span]="{xs: 12, md: 6, xl: 3}">
        <div class="d-flex position-relative">
          <div class="me-2"><i class="bi bi-phone-fill"></i></div>
          <div>
            <div class="text-uppercase mb-1">
              <a
                routerLink="/developer/tools"
                fragment="mobile-apps"
                class="stretched-link text-white text-lightblue-hover text-decoration-none">Mobile Apps
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
            <div class="text-gray-300">
              Authentication, registration, and self-service flows
            </div>
          </div>
        </div>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell [span]="{xs: 12, md: 6, xl: 3}">
        <div class="d-flex position-relative">
          <div class="me-2"><i class="bi bi-diagram-2-fill"></i></div>
          <div>
            <div class="text-uppercase mb-1">
              <a
                routerLink="/developer/tools"
                fragment="custom-nodes"
                class="stretched-link text-white text-lightblue-hover text-decoration-none">Custom Nodes
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
            <div class="text-gray-300">
              Develop your own or browse existing authentication nodes
            </div>
          </div>
        </div>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell [span]="{xs: 12, md: 6, xl: 3}">
        <div class="d-flex position-relative">
          <div class="me-2"><i class="bi bi-file-code-fill"></i></div>
          <div>
            <div class="text-uppercase mb-1">
              <a
                routerLink="/developer/tools"
                fragment="scripts"
                class="stretched-link text-white text-lightblue-hover text-decoration-none">Scripts
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
            <div class="text-gray-300">
              Customize and extend authentication journeys
            </div>
          </div>
        </div>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell [span]="{xs: 12, md: 6, xl: 3}">
        <div class="d-flex position-relative">
          <div class="me-2"><i class="bi bi-folder-symlink-fill"></i></div>
          <div>
            <div class="text-uppercase mb-1">
              <a
                routerLink="/developer/tools"
                fragment="sample-code"
                class="stretched-link text-white text-lightblue-hover text-decoration-none">Sample Code
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
            <div class="text-gray-300">
              Examples for logging, OAuth2/OIDC, connectors and more
            </div>
          </div>
        </div>
      </backstage-layout-grid-cell>
    </backstage-layout-grid>
  </div>
  <div class="pt-5">
    <h2 class="text-white mb-5 fw-bold fs-2 text-center">What's new in the Community</h2>
    <backstage-layout-grid [gap]="3">
      <backstage-layout-grid-cell [span]="{xs: 12, xl: 5}">
        @if (events.length) {
          <app-search-result-carousel
            [results]="events"
            [imageUrl]="eventImageUrl"
            [heading]="heading"
            category="events"
            backgroundClass="bg-steel-blue_to_cobalt-45 opacity-50">
            <ng-template
              #heading
              let-result>
              <div class="display-4 fw-bold mb-2">{{ result.startDateTime | date: 'MMM dd' }}</div>
            </ng-template>
          </app-search-result-carousel>
        }
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell [span]="{xs: 12, xl: 7}">
        <backstage-layout-grid [gap]="3">
          <backstage-layout-grid-cell [span]="12">
            @if (marketplaceEntries.length) {
              <app-search-result-carousel
                [results]="marketplaceEntries"
                category="marketplace"
                backgroundClass="bg-cobalt_to_verdigris-45"></app-search-result-carousel>
            }
          </backstage-layout-grid-cell>
          <backstage-layout-grid-cell [span]="{xs: 12, xl: 6}">
            @if (forumThreads.length) {
              <app-search-result-carousel
                [results]="forumThreads"
                category="forums"
                backgroundClass="bg-steel-blue_to_manganese-45"></app-search-result-carousel>
            }
          </backstage-layout-grid-cell>
          <backstage-layout-grid-cell [span]="{xs: 12, xl: 6}">
            @if (articles.length) {
              <app-search-result-carousel
                [results]="articles"
                category="articles"
                backgroundClass="bg-manganese_to_ember-45"></app-search-result-carousel>
            }
          </backstage-layout-grid-cell>
        </backstage-layout-grid>
      </backstage-layout-grid-cell>
    </backstage-layout-grid>
    <div class="pt-5">
      <div class="row flex-row-reverse">
        <div class="mb-5 mb-lg-0 col-lg-6">
          <div class="fw-bold fs-2 mb-3 text-md-end">Always stay up to date</div>
          <div class="text-md-end">
            <a
              class="text-white me-4 text-nowrap"
              href="https://twitter.com/ForgeRock"
              target="_blank"><i class="bi bi-chat-quote-fill"></i> ForgeRock Community</a>
            <a
              class="text-white text-nowrap"
              href="https://twitter.com/ForgeRock"
              target="_blank"><i class="bi bi-twitter"></i> ForgeRock News</a>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="fw-bold fs-2 mb-3">We’d love to hear from you</div>
          <div>
            You are big part of why ForgeRock is the best platform for building secure applications.
            Share your thoughts to tell us how we can improve our developer experience!
          </div>
          <div class="feedback-arrow"></div>
        </div>
      </div>
    </div>
  </div>
</div>
