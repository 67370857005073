import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  transform(value: string): string {
    const e = this.document.createElement('div');
    e.innerHTML = value;
    return e.innerText;
  }

}
