<div class="mt-3">
  <span class="me-3">Share this event</span>
  <a
    class="me-3 mb-1 btn py-1 px-2 btn-dark"
    href="https://www.facebook.com/sharer/sharer.php?u={{event.url | urlEncode}}&t={{event.title | urlEncode}}"
    target="_blank"><i class="bi-facebook"></i></a>
  <a
    class="me-3 mb-1 btn py-1 px-2 btn-dark"
    href="https://twitter.com/intent/tweet?url={{event.url | urlEncode}}&text={{event.title | urlEncode}}"
    target="_blank"><i class="bi-twitter"></i></a>
  <a
    class="mb-1 btn py-1 px-2 btn-dark"
    href="https://www.linkedin.com/shareArticle?mini=true&url={{event.url | urlEncode}}"
    target="_blank"><i class="bi-linkedin"></i></a>
</div>
