import { Component, Input, OnInit } from '@angular/core';
import { CommunityEventService } from '../community-event.service';
import { CommunityEvent } from '../model/community-event';

@Component({
  selector: 'app-event-detail-card',
  templateUrl: './event-detail-card.component.html',
  styleUrls: [ './event-detail-card.component.scss' ]
})
export class EventDetailCardComponent implements OnInit {
  @Input() event: CommunityEvent;
  registered: boolean;

  constructor(private communityEventService: CommunityEventService) {}

  async ngOnInit(): Promise<void> {
    try {
      this.registered = await this.communityEventService.isRegistered(this.event.id);
    } catch {
      // ignored
    }
  }
}
