import { inject, Injectable } from '@angular/core';
import { AbstractSearchService, BackendService, SearchResults } from '@forgerock/backstage-ui-core';
import { firstValueFrom } from 'rxjs';
import { CourseType } from './model/community-event';
import { Learner } from './model/learner';

@Injectable({
  providedIn: 'root'
})
export class LearnerService extends AbstractSearchService<Learner> {
  private baseUrl = '/university-ws/api/v1/learner';
  private backend = inject(BackendService);
  override url = `${this.baseUrl}/_search`;

  /**
   * Creates a Learner for an event. Simplifies the order flow as it directly adds a learner on an event.
   * Only pass holders are allowed to use this endpoint for live events.
   */
  createLearner(eventId: string): Promise<unknown> {
    return firstValueFrom(
      this.backend.post(this.baseUrl, { eventId, courseType: CourseType.COMMUNITY })
    );
  }

  deleteLearner(learnerId: string): Promise<void> {
    return firstValueFrom(
      this.backend.delete(`${this.baseUrl}/${learnerId}`)
    );
  }

  getPageAsync(page: number): Promise<SearchResults<Learner>> {
    return firstValueFrom(this.getPage(page));
  }
}
