<div class="container bg-developers p-5 text-white">
  <div class="mb-5">
    <h1 class="fw-bolder display-5 mb-3">
      Your ForgeRock integration starts here
    </h1>
  </div>
  <div>
    <img
      src="assets/developers/developer-tools-graphic-1.svg"
      class="mb-3"
      alt="">
    <backstage-layout-grid [gap]="3">
      <backstage-layout-grid-cell
        [span]="{xs: 12, lg: 6}"
        class=" d-flex flex-column justify-content-center">
        <h2
          class="fs-2 fw-bold"
          id="mobile-apps">Develop mobile apps <br>with
          <span class="text-clip bg-cobalt_to_verdigris text-nowrap">ForgeRock SDKs</span>
          <small class="fs-5">
            <a
              class="ms-2 text-gray-400 text-hover-white"
              target="_blank"
              href="https://backstage.forgerock.com/docs/sdks/latest/ios/tutorial.html"><i class="bi-apple"></i></a>
            <a
              class="ms-2 text-gray-400 text-hover-white"
              target="_blank"
              href="https://backstage.forgerock.com/docs/sdks/latest/android/quick-start/index.html"><i class="bi-android2"></i></a>
            <a
              class="ms-2 bg-gray-400 text-gray-900 px-1 fs-6 bg-hover-white text-decoration-none"
              target="_blank"
              href="https://backstage.forgerock.com/docs/sdks/latest/javascript/tutorial.html">JS</a>
          </small>
        </h2>
        <p class="lead">
          Making authentication, registration, and self-service journeys into your mobile applications a breeze.
        </p>
        <p class="text-gray-300">
          You can effortlessly develop and integrate android, iOs and JavaScript applications with authentication,
          registration and self service journeys.
          Get started building what matters to your customers, while our Android, iOS, JavaScript SDKs take care of all
          the low-level REST API calls.
        </p>
        <div>
          <a
            href="https://backstage.forgerock.com/docs/sdks/latest/sdks/configure-the-sdks.html"
            target="_blank"
            class="btn btn-gray-700">Read the docs</a>
        </div>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell [span]="{xs: 12, lg: 6}">
        <backstage-layout-grid-cell
          [span]="{xs: 12, lg: 6}"
          class="px-5 position-relative d-flex h-100 align-items-center">
          <img
            [ngSrc]="'assets/developers/mobile-apps.png'"
            class="img-fluid"
            alt="Custom nodes"
            height="478"
            width="800">
        </backstage-layout-grid-cell>
      </backstage-layout-grid-cell>
    </backstage-layout-grid>
    <img
      src="assets/developers/developer-tools-graphic-2.svg"
      class="my-3"
      alt="">
    <backstage-layout-grid [gap]="3">
      <backstage-layout-grid-cell
        [span]="{xs: 12, lg: 6}"
        class=" d-flex flex-column justify-content-center">
        <h2
          class="fs-2 fw-bold"
          id="custom-nodes">Alter authentication journeys <br> with
          <span class="text-clip bg-copper_to_gold text-nowrap">custom nodes</span></h2>
        <p class="lead">
          Everyone’s different - so are their journeys!
        </p>
        <p class="text-gray-300">
          With flexibility of ForgeRock platform, you can develop your own authentication nodes can develop your own
          authentication nodes, just like many others have. In addition to using the default nodes provided by
          ForgeRock, you can take your journeys to the next level by using a custom node from our Marketplace or
          developing your own for a more personalized experience.
        </p>
        <div>
          <a
            href="https://backstage.forgerock.com/docs/am/7.2/auth-nodes/preface.html"
            target="_blank"
            class="btn btn-gray-700 me-3 mb-3">Develop your own custom node</a>
          <a
            href="/marketplace"
            target="_blank"
            class="btn btn-outline-light mb-3">Visit the ForgeRock Marketplace</a>
        </div>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell
        [span]="{xs: 12, lg: 6}"
        class="px-5 position-relative d-flex h-100 align-items-center">
        <img
          [ngSrc]="'assets/developers/custom-nodes.png'"
          class="img-fluid"
          alt="Custom nodes"
          height="301"
          width="748">
      </backstage-layout-grid-cell>
    </backstage-layout-grid>
    <img
      src="assets/developers/developer-tools-graphic-3.svg"
      class="my-3"
      alt="">
    <backstage-layout-grid [gap]="3">
      <backstage-layout-grid-cell
        [span]="{xs: 12, lg: 6}"
        class=" d-flex flex-column justify-content-center">
        <h2
          class="fs-2 fw-bold"
          id="scripts">Enhance platform functionality <br> with
          <span class="text-clip bg-manganese_to_ember">scripts</span></h2>
        <p class="lead">
          At ForgeRock, we know that one size does not always fit all.
        </p>
        <p class="text-gray-300">
          That’s why we provide a scripting engine for you to modify default behaviour. The scripted decision node API
          lets you determine the path the authentication journey takes, scripted policy condition lets you add more
          context to your authorization policies, while plugins can be used to manipulate access tokens and scope
          handling in your OAuth 2.0 flows.
        </p>
        <div>
          <a
            href="https://backstage.forgerock.com/docs/am/7.2/authentication-guide/scripting-api-node.html"
            target="_blank"
            class="btn btn-gray-700 me-3 mb-3">Decision node API</a>
          <a
            href="https://backstage.forgerock.com/docs/am/7.2/oauth2-guide/customizing-oauth2-scopes.html"
            target="_blank"
            class="btn btn-gray-700 me-3 mb-3">OAuth2 scopes</a>
          <a
            href="https://backstage.forgerock.com/docs/am/7.2/authorization-guide/scripted-policy-condition.html#scripting-api-policy"
            target="_blank"
            class="btn btn-gray-700 mb-3">Policy conditions</a>
        </div>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell
        [span]="{xs: 12, lg: 6}"
        class="px-5 position-relative d-flex h-100 align-items-center">
        <img
          [ngSrc]="'assets/developers/scripts.png'"
          class="img-fluid"
          alt="Custom nodes"
          height="853"
          width="1133">
      </backstage-layout-grid-cell>
    </backstage-layout-grid>
    <img
      [ngSrc]="'assets/developers/developer-tools-graphic-4.svg'"
      class="mt-3"
      alt=""
      height="125"
      width="400">
  </div>
</div>
<div class="bg-gradient-1 text-white py-5">
  <div class="container p-5">
    <backstage-layout-grid [gap]="3">
      <backstage-layout-grid-cell [span]="{xs: 12, lg: 6}">
        <h2
          id="sample-code"
          class="fs-2 fw-bold">Use sample code to extend platform capabilities</h2>
        <ul class="list-unstyled">
          <li class="py-3 border-bottom border-light border-opacity-25">
            <i class="bi bi-arrow-right me-3"></i>
            <a
              href="https://backstage.forgerock.com/knowledge/kb/article/a25731576#Hs-qwy"
              target="_blank">Return a custom error message using the Scripted Decision node</a>
          </li>
          <li class="py-3 border-bottom border-light border-opacity-25">
            <i class="bi bi-arrow-right me-3"></i>
            <a
              href="https://backstage.forgerock.com/knowledge/kb/article/a39847787"
              target="_blank">Migrate Security Question answers into Identity Cloud</a>
          </li>
          <li class="py-3 border-bottom border-light border-opacity-25">
            <i class="bi bi-arrow-right me-3"></i>
            <a
              href="https://backstage.forgerock.com/knowledge/kb/article/a67702030"
              target="_blank">Use Identity Gateway as a route service for Cloud Foundry</a>
          </li>
          <li class="py-3 border-bottom border-light border-opacity-25">
            <i class="bi bi-arrow-right me-3"></i>
            <a
              href="https://backstage.forgerock.com/knowledge/kb/article/a85459474"
              target="_blank">Modify AM Oauth2/OIDC scripts</a>
          </li>
          <li class="py-3 border-bottom border-light border-opacity-25">
            <i class="bi bi-arrow-right me-3"></i>
            <a
              href="https://backstage.forgerock.com/knowledge/kb/article/a17705463#W-motn"
              target="_blank">Add a multi-valued attribute to a connector</a>
          </li>
          <li class="py-3 border-bottom border-light border-opacity-25">
            <i class="bi bi-arrow-right me-3"></i>
            <a
              href="https://backstage.forgerock.com/knowledge/kb/article/a29088283 "
              target="_blank">Use scripts to extend IDM capabilities</a>
          </li>
        </ul>
      </backstage-layout-grid-cell>
      <backstage-layout-grid-cell
        [span]="{xs: 12, lg: 6}"
        class="px-5 position-relative d-flex h-100 align-items-center">
        <img
          src="assets/developers/sample-code.png"
          class="img-fluid"
          alt="Custom nodes">
      </backstage-layout-grid-cell>
    </backstage-layout-grid>
  </div>
</div>
