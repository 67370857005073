import { Component, Input } from '@angular/core';
import { Learner } from '../model/learner';

@Component({
  selector: 'app-registration-detail-card',
  templateUrl: './registration-detail-card.component.html',
  styleUrls: [ './registration-detail-card.component.scss' ]
})
export class RegistrationDetailCardComponent {
  @Input() learner: Learner;
}
