import { Injectable } from '@angular/core';
import { AbstractSearchService } from '@forgerock/backstage-ui-core';
import { firstValueFrom, map } from 'rxjs';
import { EverythingSearchParams } from './model/everything-search-params';
import { EverythingSearchResult, EverythingSearchResultContainer } from './model/everything-search-result';

@Injectable({
  providedIn: 'root'
})
export class EverythingSearchService<T extends EverythingSearchResult = EverythingSearchResult>
  extends AbstractSearchService<T, EverythingSearchResultContainer<T>> {
  override params = new EverythingSearchParams();
  override readonly url = '/search-ws/api/v1/everything/_search';

  getResults(): Promise<T[]> {
    return firstValueFrom(
      this
        .withCount(5)
        .getPage(0)
        .pipe(
          map(it => it.results)
        )
    );
  }

}
