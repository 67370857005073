export interface CommunityEvent {
  id: string;
  startInstant: string;
  endInstant: string;
  timezone: string;
  language: string;
  availablePlaces: number;
  learningCategories: string[];
  guestSpeaker: string;
  modality: string;
  venueLocation: string;
  summary: string;
  series: string;
  registrationUrl: string;
  title: string;
  url: string;
  tags: string[];
  courseType: CourseType
}

export enum CourseType {
  ACCREDITATION = 'ACCREDITATION',
  CERTIFICATION = 'CERTIFICATION',
  SELF_PACED = 'SELF_PACED',
  ON_DEMAND = 'ON_DEMAND',
  COMMUNITY = 'COMMUNITY',
  LIVE_EVENT = 'LIVE_EVENT',
  OTHER = 'OTHER',
}
