<ngb-carousel
  #carousel
  [interval]="500000"
  appSwipe
  (swipeLeft)="carousel.next()"
  (swipeRight)="carousel.prev()">
  @for (result of results; track result; let i = $index) {
    <ng-template ngbSlide>
      @if (imageUrl) {
        <div
          class="carousel-img w-100 h-100 rounded position-absolute"
          [style.background-image]="'url(' + imageUrl(result, i) + ')'">
        </div>
      }
      <div class="carousel-bg w-100 h-100 rounded {{backgroundClass}}"></div>
      <div class="text-uppercase carousel-caption caption-top text-start">{{ category ?? result.source }}</div>
      <div class="carousel-caption text-start">
        <ng-container *ngTemplateOutlet="heading; context: {$implicit: result}"></ng-container>
        <div
          class="h5 mb-2 line-clamp"
          [style.-webkit-line-clamp]="4">
          <a
            [href]="result.url"
            class="text-white"
            target="_blank">{{ result.title }}</a>
        </div>
        <div
          class="mb-3 line-clamp"
          [style.-webkit-line-clamp]="5">
          {{ result.synopsis ?? result.summary | stripHtml }}
        </div>
      </div>
    </ng-template>
  }
</ngb-carousel>
