@for (result of aggregationResults?.results; track result) {
  <div class="form-check mb-2">
    <label class="form-check-label">
      <input
        class="form-check-input"
        type="checkbox"
        (change)="toggleValue(result.value)"
        [checked]="values?.has(result.value)">
      {{ result.value | replace: '_' : ' ' }}
      <div class="badge text-dark rounded-pill bg-gray-200 fw-normal">{{ result.total | number }}</div>
    </label>
  </div>
}

