import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Theme, ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeGuard  {
  constructor(private themeService: ThemeService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.themeService.theme.next(route.data[ 'theme' ]);
    return true;
  }

  canDeactivate(): boolean {
    this.themeService.theme.next(Theme.light);
    return true;
  }

}
