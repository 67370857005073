import { DatePipe, DOCUMENT } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { toHttpParams } from '@forgerock/backstage-ui-core';
import { CommunityEvent } from '../model/community-event';
import { StripHtmlPipe } from './strip-html.pipe';

@Pipe({
  name: 'calendarLink'
})
export class CalendarLinkPipe implements PipeTransform {
  datePipe = new DatePipe('en_US');
  icsDateFormat = 'yyyyMMddTHHmmss';
  defaultDateFormat = 'yyyyMMddTHHmmssZ';
  private sanitizer = inject(DomSanitizer);
  private document = inject(DOCUMENT);
  private stripHtmlPipe = new StripHtmlPipe(this.document);

  transform(event: CommunityEvent, format: CalendarFormat): SafeResourceUrl | string {
    if (format === 'ics') {
      return this.toIcs(event);
    } else if (format === 'google') {
      return this.toGoogleCalendarLink(event);
    } else if (format === 'outlook') {
      return this.toOutlookCalendarLink(event, 'https://outlook.live.com');
    } else if (format === 'office365') {
      return this.toOutlookCalendarLink(event, 'https://outlook.office.com');
    } else if (format === 'yahoo') {
      return this.toYahooCalendarLink(event);
    } else {
      return '';
    }
  }

  formatDate(date: string, dateFormat = this.defaultDateFormat): string {
    return this.datePipe.transform(date, dateFormat);
  }

  toIcs(event: CommunityEvent) {
    const data = `BEGIN:VCALENDAR
CALSCALE:GREGORIAN
VERSION:2.0
METHOD:PUBLISH
BEGIN:VTIMEZONE
TZID:${event.timezone}
END:VTIMEZONE
BEGIN:VEVENT
URL:${event.url}
TRANSP:OPAQUE
DTSTART;TZID=${event.timezone}:${this.formatDate(event.startInstant, this.icsDateFormat)}
DTEND;TZID=${event.timezone}:${this.formatDate(event.endInstant, this.icsDateFormat)}
LOCATION:${event.venueLocation}
DESCRIPTION:${this.toDescription(event).replace(/\n/g, '\\n')}
SUMMARY:${event.title}
END:VEVENT
END:VCALENDAR`;
    const blob = new Blob([ data ], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toGoogleCalendarLink(event: CommunityEvent): string {
    const params = toHttpParams({
      action: 'TEMPLATE',
      dates: `${this.formatDate(event.startInstant)}/${this.formatDate(event.endInstant)}`,
      details: this.toDescription(event, true),
      location: event.venueLocation,
      sprop: event.url,
      text: event.title
    });

    return `https://calendar.google.com/calendar/render?${params.toString()}`;
  }

  toOutlookCalendarLink(event: CommunityEvent, baseUrl: string): string {
    const params = toHttpParams({
      allday: false,
      body: this.toDescription(event),
      startdt: event.startInstant,
      enddt: event.endInstant,
      location: event.venueLocation,
      path: 'calendar/action/compose',
      rru: 'addevent',
      subject: event.title
    });

    return `${baseUrl}/calendar/0/deeplink/compose?${params.toString()}`;
  }

  toYahooCalendarLink(event: CommunityEvent): string {
    const params = toHttpParams({
      desc: this.toDescription(event),
      dur: '',
      st: this.formatDate(event.startInstant),
      et: this.formatDate(event.endInstant),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      in_loc: event.venueLocation,
      title: event.title,
      url: event.url,
      v: '60'
    });

    return `https://calendar.yahoo.com/?${params.toString()}`;
  }

  private toDescription(event: CommunityEvent, richText = false): string {
    let description = richText ? event.summary : this.stripHtmlPipe.transform(event.summary);

    if (event.registrationUrl) {
      description += `\nJoin the video call: ${event.registrationUrl}`;
    }

    return description;
  }

}

export type CalendarFormat = 'ics' | 'google' | 'outlook' | 'office365' | 'yahoo';
