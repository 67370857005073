import { Component, inject, OnInit } from '@angular/core';
import { AlertService, PageMetaService, TryCatcher } from '@forgerock/backstage-ui-core';
import { CommunityEventService } from '../../community-event.service';
import { CommunityEvent } from '../../model/community-event';
import { EverythingSearchResult } from '../../model/everything-search-result';
import { SearchFactoryService } from '../../search-factory.service';

@Component({
  selector: 'app-developers-page',
  templateUrl: './developers-page.component.html',
  styleUrls: [ './developers-page.component.scss' ],
  providers: [ CommunityEventService ]
})
export class DevelopersPageComponent extends TryCatcher implements OnInit {
  events: CommunityEvent[] = [];
  marketplaceEntries: EverythingSearchResult[] = [];
  articles: EverythingSearchResult[] = [];
  forumThreads: EverythingSearchResult[] = [];
  private pageMetaService = inject(PageMetaService);
  protected alertService = inject(AlertService);
  private searchFactory = inject(SearchFactoryService);
  private communityEventService = inject(CommunityEventService);

  async ngOnInit(): Promise<void> {
    this.pageMetaService.setTitle('Developers');
    const promises = [
      async () => this.events = await this.communityEventService
        .getEvents(),
      async () => this.marketplaceEntries = await this.searchFactory
        .marketplaceEntries()
        .getResults(),
      async () => this.articles = await this.searchFactory
        .knowledgeArticles()
        .getResults(),
      async () => this.forumThreads = await this.searchFactory
        .forumThreads()
        .getResults()
    ];

    await this.tryCatch(() => Promise.allSettled(promises.map(it => it())));
  }

  eventImageUrl(event: EverythingSearchResult, index: number): string {
    return `assets/eventbg${index % 6}.jpeg`;
  }

}
