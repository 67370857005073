import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appSwipe]'
})
export class SwipeDirective {
  @Output() swipeLeft = new EventEmitter();
  @Output() swipeRight = new EventEmitter();
  touchStartX = 0;

  @HostListener('touchstart', [ '$event' ])
  handleTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[ 0 ].clientX;
  }

  @HostListener('touchend', [ '$event' ])
  handleTouchEnd(event: TouchEvent) {
    if (this.touchStartX - event.changedTouches[ 0 ].clientX > 0) {
      this.swipeLeft.emit();
    } else {
      this.swipeRight.emit();
    }
    this.touchStartX = null;
  }

}
