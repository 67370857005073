import { Component, inject, OnInit } from '@angular/core';
import { AlertService, TryCatcher } from '@forgerock/backstage-ui-core';
import { CommunityEventService } from '../../community-event.service';
import { EverythingSearchService } from '../../everything-search.service';
import { CommunityEvent } from '../../model/community-event';
import { EventFilterValues } from '../../model/event-filter-values';
import { AggregationResult, AggregationResultContainer } from '../../model/everything-search-result';

@Component({
  selector: 'app-event-list-page',
  templateUrl: './event-list-page.component.html',
  styleUrls: [ './event-list-page.component.scss' ],
  providers: [ EverythingSearchService ]
})
export class EventListPageComponent extends TryCatcher implements OnInit {
  override spinning = true;
  filterableFields: (keyof CommunityEvent)[] = [ 'series', 'venueLocation', 'language', 'modality' ];
  events: CommunityEvent[] = [];
  filteredEvents: CommunityEvent[] = [];
  aggregations: AggregationResultContainer<CommunityEvent>[];
  protected alertService = inject(AlertService);
  private communityEventService = inject(CommunityEventService);

  async ngOnInit(): Promise<void> {
    await this.tryCatch(async () => {
      this.events = await this.communityEventService.getEvents();
      this.filteredEvents = [ ...this.events ];
      this.aggregations = this.getAggregations();
    });
  }

  handleFilterValuesChange(filterValues: Partial<EventFilterValues>): void {
    this.filteredEvents = this.filterEvents(this.events, filterValues);
  }

  filterEvents(events: CommunityEvent[], filterValues: Partial<EventFilterValues>): CommunityEvent[] {
    const validFilterValues = Object
      .entries(filterValues)
      .filter(([ , values ]) => values.length);

    return validFilterValues.length
      ? validFilterValues.reduce((filteredEvents, [ key, values ]) =>
        filteredEvents.filter(event => values.includes(event[ key ])), events
      )
      : events;
  }

  getAggregations(): AggregationResultContainer<CommunityEvent>[] {
    // for each aggregated field
    return this.filterableFields.map(name => {
      // count the occurrences of each value of the current field
      const values = this.events.reduce((acc, event) => {
        if (event[ name ]) {
          const value = event[ name ].toString();
          acc[ value ] = acc[ value ] ? acc[ value ] + 1 : 1;
        }
        return acc;
      }, {});
      // convert the map to an array
      const results: AggregationResult[] = Object
        .entries<number>(values)
        .map(([ value, total ]) => ({ value, total }));

      return { name, results };
    });
  }

}
