import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme = new BehaviorSubject<Theme>(Theme.light);
  darkTheme: Observable<boolean> = this.theme.pipe(map(it => it === Theme.dark));
}

export enum Theme {
  light = 'light',
  dark = 'dark'
}
