import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { toStringArray } from '@forgerock/backstage-ui-core';
import { Subscription } from 'rxjs';
import { EventFilterContainerComponent } from '../event-filter-container/event-filter-container.component';
import { CommunityEvent } from '../model/community-event';
import { AggregationResultContainer } from '../model/everything-search-result';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: [ './event-filter.component.scss' ]
})
export class EventFilterComponent implements OnInit, OnDestroy {
  @Input() filterName: keyof CommunityEvent;
  @Input() aggregationResults: AggregationResultContainer<CommunityEvent>;
  values = new Set<string>();
  private subscription: Subscription;

  constructor(private container: EventFilterContainerComponent) { }

  ngOnInit(): void {
   this.subscription = this.container.filterValuesChange.subscribe(it => {
      this.values = new Set(toStringArray(it[ this.filterName ]));
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleValue(value: string): void {
    if (this.values.has(value)) {
      this.values.delete(value);
    } else {
      this.values.add(value);
    }
    this.container.setFilterValues({ [ this.filterName ]: toStringArray(this.values) });
  }

}
