import { Component, Input, OnInit } from '@angular/core';
import { AccountService, AlertService, RoleName, SessionService, TryCatcher } from '@forgerock/backstage-ui-core';
import { CommunityEventService } from '../community-event.service';
import { CommunityEvent } from '../model/community-event';

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
  styleUrls: [ './event-registration.component.scss' ]
})
export class EventRegistrationComponent extends TryCatcher implements OnInit {
  @Input() event: CommunityEvent;
  registered: boolean;
  loggedIn: boolean;
  emailVerified: boolean;

  constructor(private communityEventService: CommunityEventService,
              private sessionService: SessionService,
              private accountService: AccountService,
              protected alertService: AlertService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.tryCatch(async () => {
      this.loggedIn = await this.sessionService.isLoggedIn();

      if (!this.loggedIn) {
        return;
      }

      this.emailVerified = await this.accountService.hasRole(RoleName.emailVerified);

      if (!this.emailVerified) {
        return;
      }

      this.registered = await this.communityEventService.isRegistered(this.event.id);
    });
  }

  register(): Promise<void> {
    return this.tryCatch(
      async () => {
        await this.communityEventService.register(this.event.id);
        this.registered = true;
      },
      'You have successfully registered on this event!',
      'Registration failed. You can report this issue at ' +
      '<a class="link-danger" href="mailto:backstage@forgerock.com">backstage@forgerock.com</a>.'
    );
  }

  redirectToLogin(): Promise<void> {
    return this.sessionService.redirectToLogin();
  }

}
