import { KeyValue } from '@angular/common';
import { Component, Output } from '@angular/core';
import { DelayedBehaviorSubject } from '@forgerock/backstage-ui-core';
import { EventFilterValues } from '../model/event-filter-values';

@Component({
  selector: 'app-event-filter-container',
  templateUrl: './event-filter-container.component.html',
  styleUrls: [ './event-filter-container.component.scss' ]
})
export class EventFilterContainerComponent {
  @Output() filterValuesChange = new DelayedBehaviorSubject<EventFilterValues>();

  setFilterValues(values: EventFilterValues): void {
    const oldValues = this.filterValuesChange.value ?? {};
    this.filterValuesChange.next({ ...oldValues, ...values });
  }

  removeFilterValue(filter: KeyValue<string, string[]>, value: string): void {
    this.setFilterValues({ [ filter.key ]: filter.value.filter(it => it !== value) });
  }

}

