<backstage-spinner [spinning]="spinning"></backstage-spinner>
<backstage-drawer
  #drawer
  [pinnable]="false"
  [zIndex]="1081"
  size="md"
  position="right">
  <header>
    <h4 class="m-0">My Event Registrations</h4>
  </header>
  <app-registration-list/>
</backstage-drawer>
<div class="container pb-5 mb-3">
  <div class="my-3">
    <h1 class="border-bottom pb-2 mb-3">Community Events</h1>
    <p class="lead">
      Upcoming events hosted by ForgeRock and the ForgeRock community to connect, collaborate and learn from experts
    </p>
  </div>
  <backstage-layout-grid [gap]="{xs: 0, sm: 3}">
    <backstage-layout-grid-cell [span]="{xs: 12, md: 3}">
      <div class="mb-3">
        <button
          class="btn btn-light"
          (click)="drawer.open = true">My Registrations
        </button>
      </div>
      <app-event-filter-container (filterValuesChange)="handleFilterValuesChange($event)">
        @for (aggregation of aggregations; track aggregation) {
          <backstage-layout-foldable-section
            [headingText]="aggregation.name | titlecase">
            <app-event-filter
              [filterName]="aggregation.name"
              [aggregationResults]="aggregation"/>
          </backstage-layout-foldable-section>
        }
      </app-event-filter-container>
    </backstage-layout-grid-cell>
    <backstage-layout-grid-cell [span]="{xs: 12, md: 9}">
      <div class="p-4 bg-white rounded shadow-sm">
        <div class="text-end text-gray-700">Sort By: <strong>Start Date</strong></div>
        <backstage-layout-grid [gap]="3">
          @for (event of filteredEvents; track event) {
            <backstage-layout-grid-cell
              [span]="{xs: 12, lg: 6, xl: 4}">
              <app-event-detail-card [event]="event"/>
            </backstage-layout-grid-cell>
          }
        </backstage-layout-grid>
      </div>
    </backstage-layout-grid-cell>
  </backstage-layout-grid>
</div>
