import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorize'
})
export class ColorizePipe implements PipeTransform {
  private colors = [ 'purple', 'darkblue', 'lightblue', 'green', 'yellow', 'red', 'gray' ];

  transform(value: any): string {
    if (!value) {
      return 'gray';
    }
    const valueAsDigits = value.toString().split('').map(it => it.charCodeAt(0)).join('');
    const index = BigInt(valueAsDigits) % BigInt(this.colors.length);
    return this.colors[ Number(index) ];
  }

}
