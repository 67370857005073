import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, PageMetaService, TryCatcher } from '@forgerock/backstage-ui-core';
import { CommunityEventService } from '../../community-event.service';
import { CommunityEvent } from '../../model/community-event';

@Component({
  selector: 'app-event-detail-page',
  templateUrl: './event-detail-page.component.html',
  styleUrls: [ './event-detail-page.component.scss' ]
})
export class EventDetailPageComponent extends TryCatcher implements OnInit {
  override spinning = true;
  event: CommunityEvent;
  eventId: string;
  private pageMetaService = inject(PageMetaService);
  private communityEventService = inject(CommunityEventService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected alertService = inject(AlertService);

  async ngOnInit(): Promise<void> {
    const { id } = this.route.snapshot.params;
    this.eventId = id;
    await this.tryCatch(async () => {
      try {
        this.event = await this.communityEventService.getEvent(id);
        this.pageMetaService.setTitle(`${this.event.title} | Events`);
      } catch (err) {
        await this.router.navigateByUrl('/');
        throw err;
      }
    });
  }

}
