import { NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ChipComponent,
  DrawerComponent,
  DrawerSpacerComponent, EllipsisComponent,
  FeedbackComponent, IconComponent,
  LayoutFoldableSectionComponent,
  LayoutGridCellComponent,
  LayoutGridComponent,
  NotificationsComponent,
  RecaptchaInputComponent,
  SpinnerComponent
} from '@forgerock/backstage-ui-components';
import { APP_NAME, PAGE_META, UiCoreModule } from '@forgerock/backstage-ui-core';
import { NgbCarouselModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DevelopersPageComponent } from './page/developers-page/developers-page.component';
import { CardWithIconComponent } from './card-with-icon/card-with-icon.component';
import { EventDetailPageComponent } from './page/event-detail-page/event-detail-page.component';
import { EventDetailCardComponent } from './event-detail-card/event-detail-card.component';
import { EventListPageComponent } from './page/event-list-page/event-list-page.component';
import { EventRegistrationComponent } from './event-registration/event-registration.component';
import { CalendarLinkPipe } from './pipe/calendar-link.pipe';
import { EventFilterComponent } from './event-filter/event-filter.component';
import { EventFilterContainerComponent } from './event-filter-container/event-filter-container.component';
import { ColorizePipe } from './pipe/colorize.pipe';
import { ReplacePipe } from './pipe/replace.pipe';
import { RegistrationListComponent } from './registration-list/registration-list.component';
import { RegistrationDetailCardComponent } from './registration-detail-card/registration-detail-card.component';
import { NavComponent } from './nav/nav.component';
import { DeveloperToolsPageComponent } from './page/developer-tools-page/developer-tools-page.component';
import { SwipeDirective } from './swipe.directive';
import { SearchResultCarouselComponent } from './search-result-carousel/search-result-carousel.component';
import { SocialMediaButtonsComponent } from './social-media-buttons/social-media-buttons.component';
import { UrlEncodePipe } from './pipe/url-encode.pipe';
import { StripHtmlPipe } from './pipe/strip-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DevelopersPageComponent,
    CardWithIconComponent,
    EventDetailPageComponent,
    EventDetailCardComponent,
    EventListPageComponent,
    EventRegistrationComponent,
    CalendarLinkPipe,
    EventFilterComponent,
    EventFilterContainerComponent,
    ColorizePipe,
    ReplacePipe,
    RegistrationListComponent,
    RegistrationDetailCardComponent,
    NavComponent,
    DeveloperToolsPageComponent,
    SwipeDirective,
    SearchResultCarouselComponent,
    SocialMediaButtonsComponent,
    UrlEncodePipe,
    StripHtmlPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbDropdownModule,
    UiCoreModule,
    AppRoutingModule,
    NgbCarouselModule,
    FormsModule,
    NgOptimizedImage,
    NotificationsComponent,
    DrawerSpacerComponent,
    SpinnerComponent,
    DrawerComponent,
    LayoutGridComponent,
    LayoutGridCellComponent,
    LayoutFoldableSectionComponent,
    RecaptchaInputComponent,
    FeedbackComponent,
    EllipsisComponent,
    ChipComponent,
    IconComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
      provide: PAGE_META,
      useValue: {
        title: 'Community | ForgeRock Backstage'
      }
    },
    {
      provide: APP_NAME,
      useValue: 'bs_community-ui'
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}
