<div class="card h-100 border-0 bg-gray-100">
  <div class="card-header bg-lightblue border-0 small d-flex text-nowrap text-gray-100">
    <div class="fw-bold">
      <i class="bi bi-calendar2-check me-2"></i> {{ event.startInstant | date: 'mediumDate' }}
    </div>
    <div class="ms-auto">
      <i class="bi bi-globe me-2"></i>
      <span>{{ event.modality }}</span>
      @if (event.modality) {
        <span
          class="vr mx-2"
        ></span>
      }
      <span>{{ event.venueLocation }}</span>
    </div>
  </div>
  <div class="card-body d-flex flex-column">
    <div>
      <h5 class="card-title fw-bolder">{{ event.title }}</h5>
      <div class="mb-1">
        @if (event.series) {
          <div
            class="badge border-{{event.series | colorize}} border text-{{event.series | colorize}} fw-normal mb-1 me-1">
            {{ event.series | replace: '_' : ' ' }}
          </div>
        }
        @if (event.tags.includes('role:partner')) {
          <div
            class="badge border-gray border text-gray fw-normal mb-2 text-uppercase">For partners
          </div>
        }
      </div>
      <div>
        <backstage-ellipsis
          [content]="event.summary | stripHtml"
          [length]="200"></backstage-ellipsis>
      </div>
      @if (event.guestSpeaker) {
        <div
          class="mt-3 pt-3 border-top border-opacity-25">
          Guest speaker: {{ event.guestSpeaker }}
        </div>
      }
    </div>
    <div class="mt-auto pt-3 d-flex align-items-center">
      @if (registered) {
        <div
          class="small text-gray-500">
          <i class="bi bi-check-circle-fill"></i> You're registered
        </div>
      }
      <a
        class="btn btn-gray-600 ms-auto stretched-link"
        routerLink="/event/{{event.id}}">Details <i class="bi bi-arrow-right"></i></a>
    </div>
  </div>
</div>
