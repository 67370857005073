<div [class.theme-dark]="themeService.darkTheme | async">
  <backstage-drawer
    position="left"
    size="sm"
    [theme]="themeService.theme | async"
    [hideExpandButton]="false"
    [isOpen]="true"
    [isPinned]="true"
    [pinnable]="false">
    <header>
      <h1 class="mb-0 fs-5 fw-light">
        <backstage-icon
          iconId="backstage/forums"
          size="2rem"></backstage-icon>
        Community
      </h1>
    </header>
    <div>
      <ul class="nav backstage-nav flex-column m-0">
        <li class="nav-item nav-item-border py-2">
          <a
            class="nav-link"
            routerLinkActive="active"
            routerLink="/events">Events</a>
        </li>
        <li class="nav-item nav-item-border py-2">
          <a
            class="nav-link"
            routerLink="/developers">Developers</a>
          <ul class="nav flex-column small">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                routerLink="/developers">Overview</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                routerLink="/developers/tools">Tools</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://github.com/ForgeRock/"><i class="bi bi-github"></i> Repositories</a>
            </li>
          </ul>
        </li>
        <li class="nav-item nav-item-border py-2">
          <a
            class="nav-link"
            routerLinkActive="active"
            href="https://community.forgerock.com">Forums</a>
        </li>
        <li class="nav-item nav-item-border py-2">
          <a
            class="nav-link"
            routerLinkActive="active"
            href="/marketplace">Marketplace</a>
        </li>
      </ul>
    </div>
  </backstage-drawer>
</div>
