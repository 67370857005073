import { Component, Input } from '@angular/core';
import { CommunityEvent } from '../model/community-event';

@Component({
  selector: 'app-social-media-buttons',
  templateUrl: './social-media-buttons.component.html',
  styleUrls: [ './social-media-buttons.component.scss' ]
})
export class SocialMediaButtonsComponent {
  @Input() event: CommunityEvent;
}
