import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-search-result-carousel',
  templateUrl: './search-result-carousel.component.html',
  styleUrls: [ './search-result-carousel.component.scss' ]
})
export class SearchResultCarouselComponent {
  @Input() results: CarouselItem[];
  @Input() backgroundClass: string;
  @Input() heading: TemplateRef<any>;
  @Input() category: string;
  @Input() imageUrl: (item: CarouselItem, index: number) => string;
}

export interface CarouselItem {
  source?: string;
  title: string;
  url?: string;
  synopsis?: string;
  summary?: string;
}
