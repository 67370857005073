import { SearchParams } from '@forgerock/backstage-ui-core';

export class EverythingSearchParams extends SearchParams {

  override get isPostBody(): boolean {
    return true;
  }

  override getHttpParamsMap(): Record<string, any> {
    return {
      ...super.getHttpParamsMap(),
      filter: this.nestedFilters?.toJSON()
    };
  }
}
