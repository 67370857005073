import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BackendService, SessionService, SimpleLoadingCache, value, WINDOW } from '@forgerock/backstage-ui-core';
import { firstValueFrom } from 'rxjs';
import { LearnerService } from './learner.service';
import { CommunityEvent, CourseType } from './model/community-event';
import { Learner, LearnerStatus } from './model/learner';

@Injectable({
  providedIn: 'root'
})
export class CommunityEventService {
  private url = '/university-ws/api/v2/community-event';
  private registrations = new SimpleLoadingCache(eventId => this.fetchRegistration(eventId));
  private events = new SimpleLoadingCache(() => this.fetchEvents());
  private sessionService = inject(SessionService);
  private backend = inject(BackendService);
  private location = inject(Location);
  private window = inject(WINDOW);
  private learnerService = inject(LearnerService);

  async getEvent(eventId: string): Promise<CommunityEvent> {
    const events = await this.getEvents();
    return events.find(it => it.id === eventId);
  }

  getEvents(): Promise<CommunityEvent[]> {
    return this.events.get();
  }

  private async fetchEvents() {
    const events: CommunityEvent[] = await firstValueFrom(this.backend.get(this.url));
    const prefix = 'COMMUNITY_';
    return events.map(it => ({
      ...it,
      url: this.window.location.origin + this.location.prepareExternalUrl(`/events/${it.id}`),
      series: it.learningCategories.find(it => it.startsWith(prefix))?.replace(prefix, '')
    }));
  }

  register(eventId: string): Promise<any> {
    return this.learnerService.createLearner(eventId);
  }

  async isRegistered(eventId: string): Promise<boolean> {
    if (!(await this.sessionService.isLoggedIn())) {
      return false;
    }
    const learner = await this.registrations.get(eventId);
    return learner?.status === LearnerStatus.ENROLLED;
  }

  async getRegistrations(): Promise<Learner[]> {
    const { results } = await this.learnerService
      .withCount(100)
      .withSort('created:desc')
      .withNestedFilter(value('active', true))
      .getPageAsync(0);

    return results.filter(it => it.event.courseType === CourseType.COMMUNITY);
  }

  private async fetchRegistration(eventId: string): Promise<Learner> {
    const { results } = await this.learnerService
      .withNestedFilter(value('eventId', eventId))
      .withSort('created:desc')
      .getPageAsync(0);

    return results[ 0 ];
  }

}
